import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const externalWalletVerificationsDomains = {
  root: (state: RootState) => state.externalWalletVerifications || initialState,
  externalWalletVerificationsList: (state: RootState) =>
    state.externalWalletVerifications?.externalWalletVerificationsList,
  isLoadingExternalWalletVerifications: (state: RootState) =>
    state.externalWalletVerifications?.isLoadingExternalWalletVerifications,
  filterExternalWalletVerifications: (state: RootState) =>
    state.externalWalletVerifications?.filterExternalWalletVerifications,
  lastPageOfExternalWalletVerificationsTable: (state: RootState) =>
    state.externalWalletVerifications
      ?.lastPageOfExternalWalletVerificationsTable,
  isAllExternalWalletVerificationsFetched: (state: RootState) =>
    state.externalWalletVerifications?.isAllExternalWalletVerificationsFetched,
  displayFilterExternalWalletVerificationsModal: (state: RootState) =>
    state.externalWalletVerifications
      ?.displayFilterExternalWalletVerificationsModal,
}

export const externalWalletVerificationsSelectors = {
  root: createSelector(externalWalletVerificationsDomains.root, root => root),
  externalWalletVerificationsList: createSelector(
    externalWalletVerificationsDomains.externalWalletVerificationsList,
    externalWalletVerificationsList => externalWalletVerificationsList,
  ),
  isLoadingExternalWalletVerifications: createSelector(
    externalWalletVerificationsDomains.isLoadingExternalWalletVerifications,
    isLoading => isLoading,
  ),
  filterExternalWalletVerifications: createSelector(
    externalWalletVerificationsDomains.filterExternalWalletVerifications,
    filterData => filterData,
  ),
  lastPageOfExternalWalletVerifications: createSelector(
    externalWalletVerificationsDomains.lastPageOfExternalWalletVerificationsTable,
    lastPageOfTable => lastPageOfTable,
  ),
  isAllExternalWalletVerificationsFetched: createSelector(
    externalWalletVerificationsDomains.isAllExternalWalletVerificationsFetched,
    isAllFetched => isAllFetched,
  ),
  displayFilterExternalWalletVerificationsModal: createSelector(
    externalWalletVerificationsDomains.displayFilterExternalWalletVerificationsModal,
    displayFilterModal => displayFilterModal,
  ),
}
