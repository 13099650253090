import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material'

import SortableTable from 'app/components/table/sortableTable'
import { externalWalletVerificationsSelectors } from '../selectors'
import { externalWalletVerificationsActions } from '../slice'
import {
  EXTERNAL_WALLET_VERIFICATIONS_TABLE_KEY,
  columnsForShowInExternalWalletVerificationsTable,
} from '../constants'
import { capitalizeAllCharacters } from 'utils/string'
import { useLocation } from 'react-router-dom'
import { searchUserSelectors } from 'app/containers/SearchUser/selector'
import { AppPages, MAX_SAFE_INTEGER } from 'app/constants'
import { redirectToBlockBox } from 'utils/redirections'
import { ISortType } from 'app/types'

export const ExternalWalletVerificationsTable = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const externalWalletVerifications = useSelector(
    externalWalletVerificationsSelectors.externalWalletVerificationsList,
  )
  const isLoadingExternalWalletVerifications = useSelector(
    externalWalletVerificationsSelectors.isLoadingExternalWalletVerifications,
  )
  const filterExternalWalletVerifications = useSelector(
    externalWalletVerificationsSelectors.filterExternalWalletVerifications,
  )
  const lastPageOfExternalWalletVerificationsTable = useSelector(
    externalWalletVerificationsSelectors.lastPageOfExternalWalletVerifications,
  )
  const isAllExternalWalletVerificationsFetched = useSelector(
    externalWalletVerificationsSelectors.isAllExternalWalletVerificationsFetched,
  )
  const searchUserCoreId = useSelector(searchUserSelectors.coreId)
  const externalWalletVerificationsList = externalWalletVerifications.map(
    item => {
      if (item.fingerprints && Array.isArray(item.fingerprints)) {
        let updatedFingerprints = item.fingerprints.map(fp => fp + '\n')
        return { ...item, fingerprints: updatedFingerprints }
      } else {
        return item
      }
    },
  )

  useEffect(() => {
    if (pathname === AppPages.SearchUser) {
      // add coreId to filterData if the user is coming from search user page
      // and the search term (coreId) is not empty
      // if the user is coming from search user page and the search term (coreId) is empty
      // remove coreId filter and get all orders

      const searchUserFilters = {
        ...filterExternalWalletVerifications,
        coreID: searchUserCoreId ? searchUserCoreId : '',
      }
      dispatch(
        externalWalletVerificationsActions.setFilterExternalWalletVerifications(
          searchUserFilters,
        ),
      )
    }
    console.log('useeffect')

    dispatch(
      externalWalletVerificationsActions.getExternalWalletVerifications(),
    )
  }, [searchUserCoreId])

  const handleSetNumberRowsPerPage = (num: number) => {
    const tablePageSizeTitle = `${EXTERNAL_WALLET_VERIFICATIONS_TABLE_KEY}_pageSize`
    localStorage.setItem(tablePageSizeTitle, num.toString())
    dispatch(
      externalWalletVerificationsActions.setFilterExternalWalletVerifications({
        ...filterExternalWalletVerifications,
        pageSize: num,
      }),
    )
    dispatch(
      externalWalletVerificationsActions.getExternalWalletVerifications(),
    )
  }

  return (
    <Wrapper>
      <SortableTable
        name={EXTERNAL_WALLET_VERIFICATIONS_TABLE_KEY}
        isLoading={isLoadingExternalWalletVerifications}
        data={externalWalletVerificationsList}
        columns={columnsForShowInExternalWalletVerificationsTable}
        handleChangeLastPage={(newPage: number) => {
          dispatch(
            externalWalletVerificationsActions.setFilterExternalWalletVerifications(
              {
                ...filterExternalWalletVerifications,
                page: newPage,
              },
            ),
          )

          dispatch(
            externalWalletVerificationsActions.getExternalWalletVerifications(),
          )
        }}
        setPage={page =>
          dispatch(
            externalWalletVerificationsActions.setLastPageOfExternalWalletVerificationsTable(
              page,
            ),
          )
        }
        page={lastPageOfExternalWalletVerificationsTable}
        setNumberRowsPerPage={num => handleSetNumberRowsPerPage(num)}
        handleCustomRequestSort={(property, item) => {
          dispatch(
            externalWalletVerificationsActions.setFilterExternalWalletVerifications(
              {
                ...filterExternalWalletVerifications,
                sortField: property,
                sortType: capitalizeAllCharacters(item) as ISortType,
              },
            ),
          )
          const oneRow = externalWalletVerificationsList[0]
          if (oneRow) {
            const propertyType = typeof oneRow[property as keyof typeof oneRow]
            if (propertyType === 'number') {
              dispatch(
                externalWalletVerificationsActions.getExternalWalletVerifications(),
              )
            }
          }
        }}
        showNumberOfPages={false}
        countOfData={MAX_SAFE_INTEGER}
        disableNextButton={isAllExternalWalletVerificationsFetched}
        onCellClick={(row, column) => {
          if (column === 'txHash') {
            redirectToBlockBox(row[column])
          }
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  justify-content: center;
  grid-gap: 10px 0;
  margin-top: 20px;
  margin-bottom: 20px;
  min-width: 0;
`
