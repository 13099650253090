import { styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useDispatch, useSelector } from 'react-redux'

import { externalWalletVerificationsSelectors } from '../selectors'
import { externalWalletVerificationsActions } from '../slice'
import {
  FilterChipsInHeader,
  IShowablefilterData,
} from 'app/components/common/FilterChipsInHeader'
import { PageHeader } from 'app/components/common/globals/pageHeader'
import PrimaryButton from 'app/components/button/primaryButton'

export const ExternalWalletVerificationsHeader = () => {
  const dispatch = useDispatch()

  const filterExternalWalletVerifications = useSelector(
    externalWalletVerificationsSelectors.filterExternalWalletVerifications,
  )

  const displayFilterExternalWalletVerificationsModal = useSelector(
    externalWalletVerificationsSelectors.displayFilterExternalWalletVerificationsModal,
  )

  const handleOpenFilterModal = () => {
    dispatch(
      externalWalletVerificationsActions.setDisplayFilterExternalWalletVerificationsModal(
        true,
      ),
    )
  }

  const showablefilterExternalWalletVerifications: IShowablefilterData[] = [
    { label: 'CoreID', value: filterExternalWalletVerifications?.coreID },
    { label: 'Status', value: filterExternalWalletVerifications?.status },
    { label: 'NFTID', value: filterExternalWalletVerifications?.nftID },
    { label: 'Id', value: filterExternalWalletVerifications?.id },
    {
      label: 'Expiration',
      value: filterExternalWalletVerifications?.expiration,
      isDate: true,
    },
    { label: 'TxHash', value: filterExternalWalletVerifications?.txHash },
    { label: 'BcTxID', value: filterExternalWalletVerifications?.bcTxID },
    { label: 'MintType', value: filterExternalWalletVerifications?.mintType },
    {
      label: 'CreatedAt From',
      value: filterExternalWalletVerifications?.createdAtFrom,
      isDate: true,
    },
    {
      label: 'CreatedAt To',
      value: filterExternalWalletVerifications?.createdAtTo,
      isDate: true,
    },
  ]

  const handleDelete = (label: string) => {
    dispatch(
      externalWalletVerificationsActions.setFilterExternalWalletVerifications({
        ...filterExternalWalletVerifications,
        [label]: undefined,
      }),
    )
    dispatch(
      externalWalletVerificationsActions.getExternalWalletVerifications(),
    )
  }

  return (
    <>
      <PageHeader title="External Wallet Verifications">
        <PrimaryButton
          onClick={handleOpenFilterModal}
          endIcon={<StyledSearchIcon />}
        >
          Search
        </PrimaryButton>
      </PageHeader>
      <FilterSection>
        {!displayFilterExternalWalletVerificationsModal && (
          <FilterChipsInHeader
            showableFilter={showablefilterExternalWalletVerifications}
            deleteFilter={handleDelete}
          />
        )}
      </FilterSection>
    </>
  )
}

const FilterSection = styled('div')`
  display: flex;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`
const StyledSearchIcon = styled(SearchIcon)`
  color: var(--white);
`
