import { useEffect, useState } from 'react'
import { Box, styled } from '@mui/material'

import { useDispatch, useSelector } from 'react-redux'
import BaseDropdownInput from 'app/components/input/dropdownInput'

import BaseInput from 'app/components/input/baseInput'
import { MaskInput } from 'app/components/input/inputWithMask'
import { getDateFromTimestamp, getTimestampFromDate } from 'utils/date'
import { DeleteForeverIcon } from 'app/components/icon/deleteForeverIcon'
import { mediaQueries } from 'styles/mediaQueries'
import PrimaryButton from 'app/components/button/primaryButton'
import { externalWalletVerificationsActions } from 'app/containers/ExternalWalletVerifications/slice'
import { externalWalletVerificationsSelectors } from 'app/containers/ExternalWalletVerifications/selectors'
import {
  EXTERNAL_WALLET_VERIFICATIONS_MINT_TYPE_OPTIONS,
  EXTERNAL_WALLET_VERIFICATIONS_STATUS_OPTIONS,
} from 'app/containers/ExternalWalletVerifications/constants'

export const FilterExternalWalletVerificationsContent = () => {
  const dispatch = useDispatch()
  const filterExternalWalletVerifications = useSelector(
    externalWalletVerificationsSelectors.filterExternalWalletVerifications,
  )

  const [createAtFrom, setCreateAtFrom] = useState<string>(
    filterExternalWalletVerifications?.createdAtFrom?.toString() ?? '',
  )
  const [createAtTo, setCreateAtTo] = useState<string>(
    filterExternalWalletVerifications?.createdAtTo?.toString() ?? '',
  )
  const [expiration, setExpiration] = useState<string>(
    filterExternalWalletVerifications?.expiration?.toString() ?? '',
  )

  const handleInputChange = (event: any) => {
    dispatch(
      externalWalletVerificationsActions.setFilterExternalWalletVerifications({
        ...filterExternalWalletVerifications,
        [event.target.name]: event.target.value,
      }),
    )
  }

  const handleDateInputChange = (event: any) => {
    const timeStamp = getTimestampFromDate(event.target.value)

    dispatch(
      externalWalletVerificationsActions.setFilterExternalWalletVerifications({
        ...filterExternalWalletVerifications,
        [event.target.name]: timeStamp,
      }),
    )
  }

  useEffect(() => {
    setCreateAtFrom(
      getDateFromTimestamp(filterExternalWalletVerifications?.createdAtFrom),
    )
    setCreateAtTo(
      getDateFromTimestamp(filterExternalWalletVerifications?.createdAtTo),
    )
  }, [])

  const handleSearching = () => {
    dispatch(
      externalWalletVerificationsActions.setDisplayFilterExternalWalletVerificationsModal(
        false,
      ),
    )
    dispatch(
      externalWalletVerificationsActions.getExternalWalletVerifications(),
    )
  }

  return (
    <Wrapper>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>CoreID:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="coreId"
            name="coreID"
            label="CoreID"
            onChange={handleInputChange}
            value={filterExternalWalletVerifications?.coreID}
            InputProps={{
              endAdornment: filterExternalWalletVerifications?.coreID ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      externalWalletVerificationsActions.setFilterExternalWalletVerifications(
                        {
                          ...filterExternalWalletVerifications,
                          coreID: '',
                        },
                      ),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>Status:</ItemLabel>
          <BaseDropdownInput
            label="Status"
            placeholder="Status"
            name="status"
            fullWidth
            options={EXTERNAL_WALLET_VERIFICATIONS_STATUS_OPTIONS}
            onChange={handleInputChange}
            value={filterExternalWalletVerifications?.status ?? ''}
            endAdornment={
              filterExternalWalletVerifications?.status ? (
                <DeleteForeverIcon
                  sx={{
                    position: 'absolute',
                    right: '20px',
                  }}
                  onClick={() =>
                    dispatch(
                      externalWalletVerificationsActions.setFilterExternalWalletVerifications(
                        {
                          ...filterExternalWalletVerifications,
                          status: '',
                        },
                      ),
                    )
                  }
                />
              ) : undefined
            }
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>MintType:</ItemLabel>
          <BaseDropdownInput
            label="MintType"
            placeholder="MintType"
            name="mintType"
            fullWidth
            options={EXTERNAL_WALLET_VERIFICATIONS_MINT_TYPE_OPTIONS}
            onChange={handleInputChange}
            value={filterExternalWalletVerifications?.mintType ?? ''}
            endAdornment={
              filterExternalWalletVerifications?.mintType ? (
                <DeleteForeverIcon
                  sx={{
                    position: 'absolute',
                    right: '20px',
                  }}
                  onClick={() =>
                    dispatch(
                      externalWalletVerificationsActions.setFilterExternalWalletVerifications(
                        {
                          ...filterExternalWalletVerifications,
                          mintType: '',
                        },
                      ),
                    )
                  }
                />
              ) : undefined
            }
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>ID:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="id"
            name="id"
            label="ID"
            onChange={handleInputChange}
            value={filterExternalWalletVerifications?.id}
            InputProps={{
              endAdornment: filterExternalWalletVerifications?.id ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      externalWalletVerificationsActions.setFilterExternalWalletVerifications(
                        {
                          ...filterExternalWalletVerifications,
                          id: '',
                        },
                      ),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>NFTID:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="nftID"
            name="nftID"
            label="NFTID"
            onChange={handleInputChange}
            value={filterExternalWalletVerifications?.nftID}
            InputProps={{
              endAdornment: filterExternalWalletVerifications?.nftID ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      externalWalletVerificationsActions.setFilterExternalWalletVerifications(
                        {
                          ...filterExternalWalletVerifications,
                          nftID: '',
                        },
                      ),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>BcTxID:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="bcTxID"
            name="bcTxID"
            label="BcTxID"
            onChange={handleInputChange}
            value={filterExternalWalletVerifications?.bcTxID}
            InputProps={{
              endAdornment: filterExternalWalletVerifications?.bcTxID ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      externalWalletVerificationsActions.setFilterExternalWalletVerifications(
                        {
                          ...filterExternalWalletVerifications,
                          bcTxID: '',
                        },
                      ),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>

      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>TxHash:</ItemLabel>
          <BaseInput
            fullWidth
            type="text"
            id="txHash"
            name="txHash"
            label="TxHash"
            onChange={handleInputChange}
            value={filterExternalWalletVerifications?.txHash}
            InputProps={{
              endAdornment: filterExternalWalletVerifications?.txHash ? (
                <DeleteForeverIcon
                  onClick={() =>
                    dispatch(
                      externalWalletVerificationsActions.setFilterExternalWalletVerifications(
                        {
                          ...filterExternalWalletVerifications,
                          txHash: '',
                        },
                      ),
                    )
                  }
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>Expiration:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={expiration}
            setMaskedValue={setExpiration}
            type="text"
            id="expiration"
            name="expiration"
            label="Expiration"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: expiration ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      externalWalletVerificationsActions.setFilterExternalWalletVerifications(
                        {
                          ...filterExternalWalletVerifications,
                          expiration: undefined,
                        },
                      ),
                    )
                    setExpiration('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>
      <TwoItemsContainer>
        <ItemConatiner>
          <ItemLabel>CreatedAt From:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={createAtFrom}
            setMaskedValue={setCreateAtFrom}
            type="text"
            id="createdAtFrom"
            name="createdAtFrom"
            label="CreatedAt From"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: createAtFrom ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      externalWalletVerificationsActions.setFilterExternalWalletVerifications(
                        {
                          ...filterExternalWalletVerifications,
                          createdAtFrom: undefined,
                        },
                      ),
                    )
                    setCreateAtFrom('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
        <ItemConatiner>
          <ItemLabel>CreatedAt To:</ItemLabel>
          <MaskInput
            fullWidth
            variant="outlined"
            maskedValue={createAtTo}
            setMaskedValue={setCreateAtTo}
            type="text"
            id="createdAtTo"
            name="createdAtTo"
            label="CreatedAt To"
            placeholder="YYYY-MM-DD"
            onChange={handleDateInputChange}
            InputProps={{
              endAdornment: createAtTo ? (
                <DeleteForeverIcon
                  onClick={() => {
                    dispatch(
                      externalWalletVerificationsActions.setFilterExternalWalletVerifications(
                        {
                          ...filterExternalWalletVerifications,
                          createdAtTo: undefined,
                        },
                      ),
                    )
                    setCreateAtTo('')
                  }}
                />
              ) : undefined,
            }}
          />
        </ItemConatiner>
      </TwoItemsContainer>

      <CTAContainer>
        <PrimaryButtons onClick={handleSearching}>Search</PrimaryButtons>
      </CTAContainer>
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
  gap: 23px;`}
  ${mediaQueries.lessThan('xs')`
  gap: 16px;`}
`

const TwoItemsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
  width: 100%;
  ${mediaQueries.lessThan('xs')`
  flex-direction: column;
  gap: 16px;
  `}
`

const ItemConatiner = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
  min-width: 452px;
  ${mediaQueries.lessThan('lg')`
  min-width: 417px;
  `}
  ${mediaQueries.lessThan('md')`
  min-width: 239px;
  `}
  ${mediaQueries.lessThan('sm')`
  min-width: 241px;
  `}
`

const ItemLabel = styled(Box)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.primary};
  word-wrap: normal;
  min-width: 120px;
  font-weight: 700;
`
const CTAContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 32px;
  margin-top: 28px;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
  margin-top: 16px;`}
  ${mediaQueries.lessThan('xs')`
  margin-top: 8px;`}
`

const PrimaryButtons = styled(PrimaryButton)`
  width: 200px;
  ${mediaQueries.lessThan('sm')`
  width: 111px;
`}
  ${mediaQueries.lessThan('xs')`
  width: 100%;`}
`
