import { ExternalWalletInvalidationsHeader } from './components/Header'
import { FilterExternalWalletInvalidationsModal } from './components/modals/filter'
import { ExternalWalletInvalidationsTable } from './components/table'

export function ExternalWalletInvalidations() {
  return (
    <>
      <ExternalWalletInvalidationsHeader />
      <ExternalWalletInvalidationsTable />
      <FilterExternalWalletInvalidationsModal />
    </>
  )
}
