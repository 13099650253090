import { styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useDispatch, useSelector } from 'react-redux'
import { externalWalletInvalidationsSelectors } from '../selectors'
import { externalWalletInvalidationsActions } from '../slice'
import {
  FilterChipsInHeader,
  IShowablefilterData,
} from 'app/components/common/FilterChipsInHeader'
import { PageHeader } from 'app/components/common/globals/pageHeader'
import PrimaryButton from 'app/components/button/primaryButton'

export const ExternalWalletInvalidationsHeader = () => {
  const dispatch = useDispatch()

  const filterExternalWalletInvalidations = useSelector(
    externalWalletInvalidationsSelectors.filterExternalWalletInvalidations,
  )
  const displayFilterExternalWalletInvalidationsModal = useSelector(
    externalWalletInvalidationsSelectors.displayFilterExternalWalletInvalidationsModal,
  )

  const handleOpenFilterModal = () => {
    dispatch(
      externalWalletInvalidationsActions.setDisplayFilterExternalWalletInvalidationsModal(
        true,
      ),
    )
  }

  const showablefilterExternalWalletInvalidations: IShowablefilterData[] = [
    { label: 'AdminID', value: filterExternalWalletInvalidations?.adminID },
    {
      label: 'UserCoreID',
      value: filterExternalWalletInvalidations?.userCoreID,
    },
    { label: 'Status', value: filterExternalWalletInvalidations?.status },
    {
      label: 'CreatedAt From',
      value: filterExternalWalletInvalidations?.createdAtFrom,
      isDate: true,
    },
    {
      label: 'CreatedAt To',
      value: filterExternalWalletInvalidations?.createdAtTo,
      isDate: true,
    },
    {
      label: 'UpdateAt From',
      value: filterExternalWalletInvalidations?.updateAtFrom,
      isDate: true,
    },
    {
      label: 'UpdateAt To',
      value: filterExternalWalletInvalidations?.updateAtTo,
      isDate: true,
    },
  ]

  const handleDelete = (label: string) => {
    dispatch(
      externalWalletInvalidationsActions.setFilterExternalWalletInvalidations({
        ...filterExternalWalletInvalidations,
        [label]: undefined,
      }),
    )
    dispatch(
      externalWalletInvalidationsActions.getExternalWalletInvalidations(),
    )
  }

  return (
    <>
      <PageHeader title="External Wallet Invalidations">
        <PrimaryButton
          onClick={handleOpenFilterModal}
          endIcon={<StyledSearchIcon />}
        >
          Search
        </PrimaryButton>
      </PageHeader>
      <FilterSection>
        {!displayFilterExternalWalletInvalidationsModal && (
          <FilterChipsInHeader
            showableFilter={showablefilterExternalWalletInvalidations}
            deleteFilter={handleDelete}
          />
        )}
      </FilterSection>
    </>
  )
}

const FilterSection = styled('div')`
  display: flex;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`
const StyledSearchIcon = styled(SearchIcon)`
  color: var(--white);
`
