import { ColumnOfTable } from 'app/components/table/sortableTable'
import { getTableSettingsFromStorage } from 'utils/storage'

export const EXTERNAL_WALLET_VERIFICATIONS_TABLE_KEY =
  'externalWalletVerificationsTable'

export const columnsForShowInExternalWalletVerificationsTable: ColumnOfTable[] =
  [
    {
      id: 'coreID',
      label: 'CoreID',
    },
    {
      id: 'nftID',
      label: 'NFT ID',
    },
    {
      id: 'status',
      label: 'Status',
    },
    {
      id: 'mintType',
      label: 'Mint Type',
    },
    {
      id: 'fingerprints',
      label: 'Fingerprints',
    },
    {
      id: 'txHash',
      label: 'TxHash',
    },

    {
      id: 'bctxID',
      label: 'BCTX ID',
    },
    {
      id: 'createdAt',
      label: 'Created At',
      isDate: true,
    },

    {
      id: 'expiration',
      label: 'Expiration',
      isDate: true,
    },
  ]

export const {
  pageSize: localPageSize,
  sortType: localSortType,
  sortField: localSortField,
} = getTableSettingsFromStorage({
  tableKey: EXTERNAL_WALLET_VERIFICATIONS_TABLE_KEY,
})

export const EXTERNAL_WALLET_VERIFICATIONS_STATUS_OPTIONS = [
  'NEWLY_ADDED',
  'IN_PROGRESS',
  'UNKNOWN_FAILED',
  'FAILED',
  'SUCCESSFUL',
]

export const EXTERNAL_WALLET_VERIFICATIONS_MINT_TYPE_OPTIONS = [
  'REJECT',
  'CONFIRM',
]
