import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { IFilterExternalWalletVerifications } from '../types'
import { ExternalWalletVerificationsEndpoints } from 'api/endpoints/verifications'

export function* getexternalWalletVerificationsDataReq(
  filterData: IFilterExternalWalletVerifications,
) {
  const response: AxiosResponse = yield axiosInstance.get(
    ExternalWalletVerificationsEndpoints.ExternalWalletVerificationsList(
      filterData,
    ),
  )
  return response
}
