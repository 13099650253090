import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const externalWalletRejectionsDomains = {
  root: (state: RootState) => state.externalWalletRejections || initialState,
  externalWalletRejectionsList: (state: RootState) =>
    state.externalWalletRejections.externalWalletRejectionsList,
  isLoadingExternalWalletRejections: (state: RootState) =>
    state.externalWalletRejections.isLoadingExternalWalletRejections,
  filterExternalWalletRejections: (state: RootState) =>
    state.externalWalletRejections.filterExternalWalletRejections,
  lastPageOfExternalWalletRejectionsTable: (state: RootState) =>
    state.externalWalletRejections.lastPageOfExternalWalletRejectionsTable,
  isAllExternalWalletRejectionsFetched: (state: RootState) =>
    state.externalWalletRejections.isAllExternalWalletRejectionsFetched,
  displayFilterExternalWalletRejectionsModal: (state: RootState) =>
    state.externalWalletRejections.displayFilterExternalWalletRejectionsModal,
}

export const externalWalletRejectionsSelectors = {
  root: createSelector(externalWalletRejectionsDomains.root, root => root),
  externalWalletRejectionsList: createSelector(
    externalWalletRejectionsDomains.externalWalletRejectionsList,
    externalWalletRejections => externalWalletRejections,
  ),
  isLoadingExternalWalletRejections: createSelector(
    externalWalletRejectionsDomains.isLoadingExternalWalletRejections,
    isLoading => isLoading,
  ),
  filterExternalWalletRejections: createSelector(
    externalWalletRejectionsDomains.filterExternalWalletRejections,
    filterData => filterData,
  ),
  lastPageOfExternalWalletRejectionsTable: createSelector(
    externalWalletRejectionsDomains.lastPageOfExternalWalletRejectionsTable,
    lastPageOfTable => lastPageOfTable,
  ),
  isAllExternalWalletRejectionsFetched: createSelector(
    externalWalletRejectionsDomains.isAllExternalWalletRejectionsFetched,
    isAllFetched => isAllFetched,
  ),
  displayFilterExternalWalletRejectionsModal: createSelector(
    externalWalletRejectionsDomains.displayFilterExternalWalletRejectionsModal,
    displayFilterModal => displayFilterModal,
  ),
}
