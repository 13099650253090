import { PayloadAction } from '@reduxjs/toolkit'
import { ExternalWalletInvalidationsState } from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'

import { externalWalletInvalidationsSaga as externalWalletInvalidationsSaga } from './saga'
import { localPageSize, localSortField, localSortType } from './constants'

export const initialState: ExternalWalletInvalidationsState = {
  externalWalletInvalidationsList: [],
  isLoadingexternalWalletInvalidations: false,
  filterExternalWalletInvalidations: {
    page: 0,
    pageSize: localPageSize ? localPageSize : 100,
    sortField: localSortField,
    sortType: localSortType,
  },
  lastPageOfExternalWalletInvalidationsTable: 0,
  isAllExternalWalletInvalidationsFetched: false,
  displayFilterExternalWalletInvalidationsModal: false,
}

const externalWalletInvalidationsSlice = createSlice({
  name: 'externalWalletInvalidations',
  initialState,
  reducers: {
    getExternalWalletInvalidations(state) {},
    setExternalWalletInvalidations(
      state,
      action: PayloadAction<
        ExternalWalletInvalidationsState['externalWalletInvalidationsList']
      >,
    ) {
      state.externalWalletInvalidationsList = action.payload
    },
    setIsLoadingExternalWalletInvalidations(
      state,
      action: PayloadAction<
        ExternalWalletInvalidationsState['isLoadingexternalWalletInvalidations']
      >,
    ) {
      state.isLoadingexternalWalletInvalidations = action.payload
    },
    setFilterExternalWalletInvalidations(
      state,
      action: PayloadAction<
        ExternalWalletInvalidationsState['filterExternalWalletInvalidations']
      >,
    ) {
      state.filterExternalWalletInvalidations = action.payload
    },
    setLastPageOfExternalWalletInvalidationsTable(
      state,
      action: PayloadAction<number>,
    ) {
      state.lastPageOfExternalWalletInvalidationsTable = action.payload
    },
    setIsAllExternalWalletInvalidationsFetched(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.isAllExternalWalletInvalidationsFetched = action.payload
    },
    setDisplayFilterExternalWalletInvalidationsModal(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.displayFilterExternalWalletInvalidationsModal = action.payload
    },
  },
})

export const {
  actions: externalWalletInvalidationsActions,
  reducer: externalWalletInvalidationsReducer,
  name: sliceKey,
} = externalWalletInvalidationsSlice

export const useExternalWalletInvalidationsSlice = () => {
  useInjectReducer({
    key: sliceKey,
    reducer: externalWalletInvalidationsReducer,
  })
  useInjectSaga({ key: sliceKey, saga: externalWalletInvalidationsSaga })
  return { externalWalletInvalidationsActions }
}
