export const VoucherNames = [
  'IDCardVerifier',
  'PassportVerifier',
  'ResidencePermitVerifier',
  'DriverLicenseVerifier',
  'AddressVerifier',
  'EmailVerifier',
  'PhoneVerifier',
  'ExternalWalletVerifier',
]
