import { useDispatch, useSelector } from 'react-redux'
import { FilterExternalWalletVerificationsContent } from './content'
import { Modal } from 'app/components/modal'
import { externalWalletVerificationsSelectors } from 'app/containers/ExternalWalletVerifications/selectors'
import { externalWalletVerificationsActions } from 'app/containers/ExternalWalletVerifications/slice'

export const FilterExternalWalletVerificationsModal = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(
    externalWalletVerificationsSelectors.displayFilterExternalWalletVerificationsModal,
  )
  const handleModalClose = () => {
    dispatch(
      externalWalletVerificationsActions.setDisplayFilterExternalWalletVerificationsModal(
        false,
      ),
    )
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <FilterExternalWalletVerificationsContent />
    </Modal>
  )
}
