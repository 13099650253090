import adminIcon from 'assets/svg/admin.svg'
import adminsIcon from 'assets/svg/admins.svg'
import apiKeysIcon from 'assets/svg/apiKeys.svg'
import assetsManagementIcon from 'assets/svg/assetsManagement.svg'
import botOrdersicon from 'assets/svg/botOrders.svg'
import checkIcon from 'assets/svg/check.svg'
import clientsIcon from 'assets/svg/clients.svg'
import configurationsIcon from 'assets/svg/configurations.svg'
import connectorIcon from 'assets/svg/connector.svg'
import documentInvalidationIcon from 'assets/svg/documentInvalidation.svg'
import documentRejectionIcon from 'assets/svg/documentRejection.svg'
import domiansIcon from 'assets/svg/domains.svg'
import emailInvalidationIcon from 'assets/svg/email_fail.svg'
import emailVerificationIcon from 'assets/svg/email_successfully.svg'
import emailRejectionIcon from 'assets/svg/emailRejection.svg'
import oauthsIcon from 'assets/svg/oauths.svg'
import orderIcon from 'assets/svg/order.svg'
import organizationsIcon from 'assets/svg/organizations.svg'
import paymentIcon from 'assets/svg/payment.svg'
import phoneInvalidationIcon from 'assets/svg/phoneInvalidation.svg'
import phoneRejectionsIcon from 'assets/svg/phoneRejection.svg'
import phoneVerificationIcon from 'assets/svg/phoneValidation.svg'
import pingIcon from 'assets/svg/ping.svg'
import recordIcon from 'assets/svg/records.svg'
import resubmitIcon from 'assets/svg/resubmit.svg'
import rolesIcon from 'assets/svg/roles.svg'
import searchUserIcon from 'assets/svg/searchUser.svg'
import shopIcon from 'assets/svg/shop.svg'
import clientSourceKeysIcon from 'assets/svg/sourceKey.svg'
import transmissionIcon from 'assets/svg/transmission.svg'
import voucherIcon from 'assets/svg/voucher.svg'
import { ITabs } from './types'

export const baseURL = process.env.REACT_APP_API_BASE_URL
export enum AppPages {
  Test = '/test',
  Login = '/login',
  LoginCallBack = '/login/callback',
  RootPage = '/',
  Sidebar = '/',
  NotFoundPage = '*',
  MainPage = '/mainpage',
  Resubmissions = '/resubmissions',
  ResubmissionItem = '/resubmission',
  Roles = '/roles',
  Admins = '/admins',
  Orders = '/orders',
  Payments = '/payments',
  Vouchers = '/vouchers',
  SearchUser = '/search-user',
  EmailVerifications = '/emailverifications',
  PhoneVerifications = '/phoneverifications',
  PhoneInvalidations = '/phoneinvalidations',
  EmailInvalidations = '/emailinvalidations',
  DocumentInvalidations = '/documentinvalidations',
  PhoneRejections = '/phonerejections',
  DocumentRejections = '/documentrejections',
  EmailRejections = '/emailrejections',
  ExternalWalletVerifications = '/externalwalletverifications',
  ExternalWalletInvalidations = '/externalwalletinvalidations',
  ExternalWalletRejections = '/externalwalletrejections',
  Transmissions = '/transmissions',
  Records = '/records',
  Voucherers = '/voucherers',
  ApiKeys = '/apikeys',
  Clients = '/clients',
  ClientSourceKeys = '/clientsourcekeys',
  Oauths = '/oauths',
  Organizations = '/organizations',
  Domains = '/domains',
  AssetsManagement = '/assetsmanagement',
  Configurations = '/configurations',
  BotOrders = '/botorders',
}
export const baseURLPrefix = '/admin-service/api/v1/'

export const ContractNames = {
  AndroidLink: 'androidLink',
  IosLink: 'iosLink',
  IsMaintaining: 'isMaintaining',
  LatestVersion: 'latestVersion',
  MinVersion: 'minVersion',
  EmailVerifier: 'EmailVerifier',
  IDCardVerifier: 'IDCardVerifier',
  ExternalWalletVerifier: 'ExternalWalletVerifier',
  KYCTransmitter: 'KYCTransmitter',
  PassportVerifier: 'PassportVerifier',
  PhoneVerifier: 'PhoneVerifier',
  ResidencePermitVerifier: 'ResidencePermitVerifier',
  CTNOracle: 'CTNOracle',
  KYCVault: 'KYCVault',
  XCBOracle: 'XCBOracle',
  NameService: 'NameService',
  CTN: 'CTN',
  DriverLicenseVerifier: 'DriverLicenseVerifier',
  AddressVerifier: 'AddressVerifier',
}

export const DocumentSubmittedTypes = {
  ADDRESS: 'Address',
  IDCARD: 'IDCard',
  PASSPORT: 'Passport',
  RESIDENCEPERMIT: 'ResidencePermit',
  DRIVERLICENSE: 'DriverLicense',
  ExternalWallet: 'ExternalWallet',
  EMAIL: 'Email',
  PHONE: 'Phone',
}

export const TABS: ITabs = {
  //DO NOT REMOVE THIS COMMENT
  // MainPage: {
  //   name: 'MainPage',
  //   img: metricsIcon,
  //   routeTo: AppPages.MainPage,
  // },
  Verifications: {
    name: 'Verifications',
    img: checkIcon,
    routeTo: '',
    children: [
      {
        name: 'Resubmissions',
        img: resubmitIcon,
        routeTo: AppPages.Resubmissions,
      },
      {
        name: 'DocumentInvalidations',
        img: documentInvalidationIcon,
        routeTo: AppPages.DocumentInvalidations,
      },
      {
        name: 'DocumentRejections',
        img: documentRejectionIcon,
        routeTo: AppPages.DocumentRejections,
      },
      {
        name: 'EmailVerifications',
        img: emailVerificationIcon,
        routeTo: AppPages.EmailVerifications,
      },
      {
        name: 'EmailInvalidations',
        img: emailInvalidationIcon,
        routeTo: AppPages.EmailInvalidations,
      },
      {
        name: 'EmailRejections',
        img: emailRejectionIcon,
        routeTo: AppPages.EmailRejections,
      },
      {
        name: 'ExternalWalletVerifications',
        img: emailVerificationIcon,
        routeTo: AppPages.ExternalWalletVerifications,
      },
      {
        name: 'ExternalWalletInvalidations',
        img: emailInvalidationIcon,
        routeTo: AppPages.ExternalWalletInvalidations,
      },
      {
        name: 'ExternalWalletRejections',
        img: emailRejectionIcon,
        routeTo: AppPages.ExternalWalletRejections,
      },
      {
        name: 'PhoneVerifications',
        img: phoneVerificationIcon,
        routeTo: AppPages.PhoneVerifications,
      },
      {
        name: 'PhoneInvalidations',
        img: phoneInvalidationIcon,
        routeTo: AppPages.PhoneInvalidations,
      },
      {
        name: 'PhoneRejections',
        img: phoneRejectionsIcon,
        routeTo: AppPages.PhoneRejections,
      },
    ],
  },

  Shop: {
    name: 'Shop',
    img: shopIcon,
    routeTo: '',
    children: [
      {
        name: 'Orders',
        img: orderIcon,
        routeTo: AppPages.Orders,
      },
      {
        name: 'Payments',
        img: paymentIcon,
        routeTo: AppPages.Payments,
      },
      {
        name: 'Vouchers',
        img: voucherIcon,
        routeTo: AppPages.Vouchers,
      },
    ],
  },
  Connector: {
    name: 'Connector',
    img: connectorIcon,
    routeTo: '',
    children: [
      {
        name: 'Transmissions',
        img: transmissionIcon,
        routeTo: AppPages.Transmissions,
      },
      {
        name: 'Records',
        img: recordIcon,
        routeTo: AppPages.Records,
      },
      {
        name: 'Domains',
        img: domiansIcon,
        routeTo: AppPages.Domains,
      },
      {
        name: 'ApiKeys',
        img: apiKeysIcon,
        routeTo: AppPages.ApiKeys,
      },
      {
        name: 'Clients',
        img: clientsIcon,
        routeTo: AppPages.Clients,
      },
      {
        name: 'ClientSourceKeys',
        img: clientSourceKeysIcon,
        routeTo: AppPages.ClientSourceKeys,
      },
      {
        name: 'Oauths',
        img: oauthsIcon,
        routeTo: AppPages.Oauths,
      },
      {
        name: 'Organizations',
        img: organizationsIcon,
        routeTo: AppPages.Organizations,
      },
    ],
  },
  AdminManagment: {
    name: 'AdminManagment',
    img: adminIcon,
    routeTo: '',
    children: [
      {
        name: 'Admins',
        img: adminsIcon,
        routeTo: AppPages.Admins,
      },
      {
        name: 'Roles',
        img: rolesIcon,
        routeTo: AppPages.Roles,
      },
      {
        name: 'SearchUser',
        img: searchUserIcon,
        routeTo: AppPages.SearchUser,
      },
    ],
  },
  Ping: {
    name: 'Ping',
    img: pingIcon,
    routeTo: '',
    children: [
      {
        name: 'AssetsManagement',
        img: assetsManagementIcon,
        routeTo: AppPages.AssetsManagement,
      },
      {
        name: 'Configurations',
        img: configurationsIcon,
        routeTo: AppPages.Configurations,
      },
      {
        name: 'BotOrders',
        img: botOrdersicon,
        routeTo: AppPages.BotOrders,
      },
    ],
  },
}

export const MAX_SAFE_INTEGER = 10000

export const THIRTY_MINUTES = 30 * 60 * 1000
