import { call, put, select, takeLatest } from 'redux-saga/effects'
import { externalWalletInvalidationsActions } from './slice'
import { getDocumentInvalidationsReq } from './providers'
import { AxiosResponse } from 'axios'
import { customToast } from 'app/components/toast/CustomToast'
import { errorMessage } from 'utils/parser'
import { externalWalletInvalidationsSelectors } from './selectors'
import {
  IFilterExternalWalletInvalidations,
  IExternalWalletInvalidation,
} from './types'

function* getExternalWalletInvalidations(): any {
  yield put(
    externalWalletInvalidationsActions.setIsLoadingExternalWalletInvalidations(
      true,
    ),
  )
  try {
    const filterExternalWalletInvalidations: IFilterExternalWalletInvalidations =
      yield select(
        externalWalletInvalidationsSelectors.filterExternalWalletInvalidations,
      )

    const response: AxiosResponse = yield call(
      getDocumentInvalidationsReq,
      filterExternalWalletInvalidations,
    )

    const externalWalletInvalidationsInvalidationsList = (response?.data?.data
      ?.invalidations || []) as IExternalWalletInvalidation[]

    yield put(
      externalWalletInvalidationsActions.setExternalWalletInvalidations(
        externalWalletInvalidationsInvalidationsList,
      ),
    )

    externalWalletInvalidationsInvalidationsList.length === 0 ||
    externalWalletInvalidationsInvalidationsList.length <
      filterExternalWalletInvalidations.pageSize
      ? yield put(
          externalWalletInvalidationsActions.setIsAllExternalWalletInvalidationsFetched(
            true,
          ),
        )
      : yield put(
          externalWalletInvalidationsActions.setIsAllExternalWalletInvalidationsFetched(
            false,
          ),
        )
  } catch (error) {
    customToast.error(errorMessage(error))
  } finally {
    yield put(
      externalWalletInvalidationsActions.setIsLoadingExternalWalletInvalidations(
        false,
      ),
    )
  }
}

export function* externalWalletInvalidationsSaga() {
  yield takeLatest(
    externalWalletInvalidationsActions.getExternalWalletInvalidations.type,
    getExternalWalletInvalidations,
  )
}
