import axiosInstance from 'api/axios'
import { AxiosResponse } from 'axios'
import { IFilterExternalWalletRejections } from '../types'
import { ExternalWalletRejectionsEndpoints } from 'api/endpoints/verifications'

export function* getExternalWalletRejectionsReq(
  filterData: IFilterExternalWalletRejections,
) {
  const response: AxiosResponse = yield axiosInstance.get(
    ExternalWalletRejectionsEndpoints.externalWalletRejectionsList(filterData),
  )
  return response
}
