import { useDispatch, useSelector } from 'react-redux'

import { FilterExternalWalletInvalidationsContent } from './content'

import { Modal } from 'app/components/modal'
import { externalWalletInvalidationsSelectors } from 'app/containers/ExternalWalletInvalidations/selectors'
import { externalWalletInvalidationsActions } from 'app/containers/ExternalWalletInvalidations/slice'

export const FilterExternalWalletInvalidationsModal = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(
    externalWalletInvalidationsSelectors.displayFilterExternalWalletInvalidationsModal,
  )
  const handleModalClose = () => {
    dispatch(
      externalWalletInvalidationsActions.setDisplayFilterExternalWalletInvalidationsModal(
        false,
      ),
    )
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <FilterExternalWalletInvalidationsContent />
    </Modal>
  )
}
