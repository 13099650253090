import { call, put, select, takeLatest } from 'redux-saga/effects'
import { externalWalletRejectionsActions } from './slice'
import { getExternalWalletRejectionsReq } from './providers'
import { AxiosResponse } from 'axios'
import { customToast } from 'app/components/toast/CustomToast'
import { errorMessage } from 'utils/parser'
import { externalWalletRejectionsSelectors } from './selectors'
import {
  IFilterExternalWalletRejections,
  IExternalWalletRejection,
} from './types'

function* getExternalWalletRejections(): any {
  yield put(
    externalWalletRejectionsActions.setIsLoadingExternalWalletRejections(true),
  )
  try {
    const filterExternalWalletRejections: IFilterExternalWalletRejections =
      yield select(
        externalWalletRejectionsSelectors.filterExternalWalletRejections,
      )

    const response: AxiosResponse = yield call(
      getExternalWalletRejectionsReq,
      filterExternalWalletRejections,
    )

    const externalWalletRejectionsList = (response?.data?.data?.rejections ||
      []) as IExternalWalletRejection[]

    yield put(
      externalWalletRejectionsActions.setExternalWalletRejections(
        externalWalletRejectionsList,
      ),
    )

    externalWalletRejectionsList.length === 0 ||
    externalWalletRejectionsList.length <
      filterExternalWalletRejections.pageSize
      ? yield put(
          externalWalletRejectionsActions.setIsAllExternalWalletRejectionsFetched(
            true,
          ),
        )
      : yield put(
          externalWalletRejectionsActions.setIsAllExternalWalletRejectionsFetched(
            false,
          ),
        )
  } catch (error) {
    customToast.error(errorMessage(error))
  } finally {
    yield put(
      externalWalletRejectionsActions.setIsLoadingExternalWalletRejections(
        false,
      ),
    )
  }
}

export function* externalWalletRejectionsSaga() {
  yield takeLatest(
    externalWalletRejectionsActions.getExternalWalletRejections.type,
    getExternalWalletRejections,
  )
}
