import { PayloadAction } from '@reduxjs/toolkit'
import { ExternalWalletVerificationsState } from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'
import { externalWalletVerificationsSaga } from './saga'
import { localPageSize, localSortField, localSortType } from './constants'

export const initialState: ExternalWalletVerificationsState = {
  externalWalletVerificationsList: [],
  isLoadingExternalWalletVerifications: false,
  filterExternalWalletVerifications: {
    page: 0,
    pageSize: localPageSize ? localPageSize : 100,
    sortField: localSortField,
    sortType: localSortType,
  },
  lastPageOfExternalWalletVerificationsTable: 0,
  isAllExternalWalletVerificationsFetched: false,
  displayFilterExternalWalletVerificationsModal: false,
}

const externalWalletVerificationsSlice = createSlice({
  name: 'externalWalletVerifications',
  initialState,
  reducers: {
    getExternalWalletVerifications(state) {},
    setExternalWalletVerificationsList(
      state,
      action: PayloadAction<
        ExternalWalletVerificationsState['externalWalletVerificationsList']
      >,
    ) {
      state.externalWalletVerificationsList = action.payload
    },
    setIsLoadingExternalWalletVerifications(
      state,
      action: PayloadAction<
        ExternalWalletVerificationsState['isLoadingExternalWalletVerifications']
      >,
    ) {
      state.isLoadingExternalWalletVerifications = action.payload
    },
    setFilterExternalWalletVerifications(
      state,
      action: PayloadAction<
        ExternalWalletVerificationsState['filterExternalWalletVerifications']
      >,
    ) {
      state.filterExternalWalletVerifications = action.payload
    },
    setLastPageOfExternalWalletVerificationsTable(
      state,
      action: PayloadAction<number>,
    ) {
      state.lastPageOfExternalWalletVerificationsTable = action.payload
    },
    setIsAllExternalWalletVerificationsFetched(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.isAllExternalWalletVerificationsFetched = action.payload
    },
    setDisplayFilterExternalWalletVerificationsModal(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.displayFilterExternalWalletVerificationsModal = action.payload
    },
  },
})

export const {
  actions: externalWalletVerificationsActions,
  reducer: externalWalletVerificationsReducer,
  name: sliceKey,
} = externalWalletVerificationsSlice

export const useExternalWalletVerificationsSlice = () => {
  useInjectReducer({
    key: sliceKey,
    reducer: externalWalletVerificationsReducer,
  })
  useInjectSaga({ key: sliceKey, saga: externalWalletVerificationsSaga })
  return { externalWalletVerificationsActions }
}
