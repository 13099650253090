import { useDispatch, useSelector } from 'react-redux'
import { FilterExternalWalletRejectionsContent } from './content'
import { Modal } from 'app/components/modal'
import { externalWalletRejectionsSelectors } from 'app/containers/ExternalWalletRejections/selectors'
import { externalWalletRejectionsActions } from 'app/containers/ExternalWalletRejections/slice'

export const FilterExternalWalletRejectionsModal = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(
    externalWalletRejectionsSelectors.displayFilterExternalWalletRejectionsModal,
  )
  const handleModalClose = () => {
    dispatch(
      externalWalletRejectionsActions.setDisplayFilterExternalWalletRejectionsModal(
        false,
      ),
    )
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <FilterExternalWalletRejectionsContent />
    </Modal>
  )
}
