import { PayloadAction } from '@reduxjs/toolkit'
import { ExternalWalletRejectionsState } from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'

import { externalWalletRejectionsSaga } from './saga'
import { localPageSize, localSortField, localSortType } from './constants'

export const initialState: ExternalWalletRejectionsState = {
  externalWalletRejectionsList: [],
  isLoadingExternalWalletRejections: false,
  filterExternalWalletRejections: {
    page: 0,
    pageSize: localPageSize ? localPageSize : 100,
    sortField: localSortField,
    sortType: localSortType,
  },
  lastPageOfExternalWalletRejectionsTable: 0,
  isAllExternalWalletRejectionsFetched: false,
  displayFilterExternalWalletRejectionsModal: false,
}

const externalWalletRejectionsSlice = createSlice({
  name: 'externalWalletRejections',
  initialState,
  reducers: {
    getExternalWalletRejections(state) {},
    setExternalWalletRejections(
      state,
      action: PayloadAction<
        ExternalWalletRejectionsState['externalWalletRejectionsList']
      >,
    ) {
      state.externalWalletRejectionsList = action.payload
    },
    setIsLoadingExternalWalletRejections(
      state,
      action: PayloadAction<
        ExternalWalletRejectionsState['isLoadingExternalWalletRejections']
      >,
    ) {
      state.isLoadingExternalWalletRejections = action.payload
    },
    setFilterExternalWalletRejections(
      state,
      action: PayloadAction<
        ExternalWalletRejectionsState['filterExternalWalletRejections']
      >,
    ) {
      state.filterExternalWalletRejections = action.payload
    },
    setLastPageOfExternalWalletRejectionsTable(
      state,
      action: PayloadAction<number>,
    ) {
      state.lastPageOfExternalWalletRejectionsTable = action.payload
    },
    setIsAllExternalWalletRejectionsFetched(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.isAllExternalWalletRejectionsFetched = action.payload
    },
    setDisplayFilterExternalWalletRejectionsModal(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.displayFilterExternalWalletRejectionsModal = action.payload
    },
  },
})

export const {
  actions: externalWalletRejectionsActions,
  reducer: externalWalletRejectionsReducer,
  name: sliceKey,
} = externalWalletRejectionsSlice

export const useExternalWalletRejectionsSlice = () => {
  useInjectReducer({ key: sliceKey, reducer: externalWalletRejectionsReducer })
  useInjectSaga({ key: sliceKey, saga: externalWalletRejectionsSaga })
  return { externalWalletRejectionsActions }
}
