import { ExternalWalletVerificationsTable } from './components/table'
import { ExternalWalletVerificationsHeader } from './components/Header'
import { FilterExternalWalletVerificationsModal } from './components/modals/filter'

export function ExternalWalletVerifications() {
  return (
    <>
      <ExternalWalletVerificationsHeader />
      <ExternalWalletVerificationsTable />
      <FilterExternalWalletVerificationsModal />
    </>
  )
}
