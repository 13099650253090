import { ExternalWalletRejectionsHeader } from './components/Header'
import { FilterExternalWalletRejectionsModal } from './components/modals/filter'
import { ExternalWalletRejectionsTable } from './components/table'

export function ExternalWalletRejections() {
  return (
    <>
      <ExternalWalletRejectionsHeader />
      <ExternalWalletRejectionsTable />
      <FilterExternalWalletRejectionsModal />
    </>
  )
}
