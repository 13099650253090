import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'store/types'
import { initialState } from './slice'

export const externalWalletInvalidationsDomains = {
  root: (state: RootState) => state.externalWalletInvalidations || initialState,
  externalWalletInvalidationsList: (state: RootState) =>
    state.externalWalletInvalidations?.externalWalletInvalidationsList || [],
  isLoadingExternalWalletInvalidations: (state: RootState) =>
    state.externalWalletInvalidations?.isLoadingexternalWalletInvalidations ||
    false,
  filterExternalWalletInvalidations: (state: RootState) =>
    state.externalWalletInvalidations?.filterExternalWalletInvalidations,
  lastPageOfExternalWalletInvalidationsTable: (state: RootState) =>
    state.externalWalletInvalidations
      ?.lastPageOfExternalWalletInvalidationsTable,
  isAllExternalWalletInvalidationsFetched: (state: RootState) =>
    state.externalWalletInvalidations
      ?.isAllExternalWalletInvalidationsFetched || false,
  displayFilterExternalWalletInvalidationsModal: (state: RootState) =>
    state.externalWalletInvalidations
      ?.displayFilterExternalWalletInvalidationsModal || false,
}

export const externalWalletInvalidationsSelectors = {
  root: createSelector(externalWalletInvalidationsDomains.root, root => root),
  externalWalletInvalidationsList: createSelector(
    externalWalletInvalidationsDomains.externalWalletInvalidationsList,
    externalWalletInvalidations => externalWalletInvalidations,
  ),
  isLoadingExternalWalletInvalidations: createSelector(
    externalWalletInvalidationsDomains.isLoadingExternalWalletInvalidations,
    isLoading => isLoading,
  ),
  filterExternalWalletInvalidations: createSelector(
    externalWalletInvalidationsDomains.filterExternalWalletInvalidations,
    filterData => filterData,
  ),
  lastPageOfExternalWalletInvalidationsTable: createSelector(
    externalWalletInvalidationsDomains.lastPageOfExternalWalletInvalidationsTable,
    lastPageOfTable => lastPageOfTable,
  ),
  isAllExternalWalletInvalidationsFetched: createSelector(
    externalWalletInvalidationsDomains.isAllExternalWalletInvalidationsFetched,
    isAllFetched => isAllFetched,
  ),
  displayFilterExternalWalletInvalidationsModal: createSelector(
    externalWalletInvalidationsDomains.displayFilterExternalWalletInvalidationsModal,
    displayFilterModal => displayFilterModal,
  ),
}
