import { styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useDispatch, useSelector } from 'react-redux'
import { externalWalletRejectionsSelectors } from '../selectors'
import { externalWalletRejectionsActions } from '../slice'
import {
  FilterChipsInHeader,
  IShowablefilterData,
} from 'app/components/common/FilterChipsInHeader'
import { PageHeader } from 'app/components/common/globals/pageHeader'
import PrimaryButton from 'app/components/button/primaryButton'

export const ExternalWalletRejectionsHeader = () => {
  const dispatch = useDispatch()

  const filterExternalWalletRejections = useSelector(
    externalWalletRejectionsSelectors.filterExternalWalletRejections,
  )
  const displayFilterExternalWalletRejectionsModal = useSelector(
    externalWalletRejectionsSelectors.displayFilterExternalWalletRejectionsModal,
  )

  const handleOpenFilterModal = () => {
    dispatch(
      externalWalletRejectionsActions.setDisplayFilterExternalWalletRejectionsModal(
        true,
      ),
    )
  }

  const showablefilterExternalWalletRejections: IShowablefilterData[] = [
    { label: 'AdminID', value: filterExternalWalletRejections?.adminID },
    { label: 'UserCoreID', value: filterExternalWalletRejections?.userCoreID },
    {
      label: 'VouchererName',
      value: filterExternalWalletRejections?.vouchererName,
    },
    { label: 'Status', value: filterExternalWalletRejections?.status },
    {
      label: 'CreatedAt From',
      value: filterExternalWalletRejections?.createdAtFrom,
      isDate: true,
    },
    {
      label: 'CreatedAt To',
      value: filterExternalWalletRejections?.createdAtTo,
      isDate: true,
    },
    {
      label: 'UpdateAt From',
      value: filterExternalWalletRejections?.updateAtFrom,
      isDate: true,
    },
    {
      label: 'UpdateAt To',
      value: filterExternalWalletRejections?.updateAtTo,
      isDate: true,
    },
  ]

  const handleDelete = (label: string) => {
    dispatch(
      externalWalletRejectionsActions.setFilterExternalWalletRejections({
        ...filterExternalWalletRejections,
        [label]: undefined,
      }),
    )
    dispatch(externalWalletRejectionsActions.getExternalWalletRejections())
  }

  return (
    <>
      <PageHeader title="External Wallet Rejections">
        <PrimaryButton
          onClick={handleOpenFilterModal}
          endIcon={<StyledSearchIcon />}
        >
          Search
        </PrimaryButton>
      </PageHeader>
      <FilterSection>
        {!displayFilterExternalWalletRejectionsModal && (
          <FilterChipsInHeader
            showableFilter={showablefilterExternalWalletRejections}
            deleteFilter={handleDelete}
          />
        )}
      </FilterSection>
    </>
  )
}

const FilterSection = styled('div')`
  display: flex;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`
const StyledSearchIcon = styled(SearchIcon)`
  color: var(--white);
`
