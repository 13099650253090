import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { externalWalletVerificationsActions } from './slice'
import { getexternalWalletVerificationsDataReq } from './providers'
import { AxiosResponse } from 'axios'
import { customToast } from 'app/components/toast/CustomToast'
import { errorMessage } from 'utils/parser'
import {
  IExternalWalletVerifications,
  IFilterExternalWalletVerifications,
} from './types'
import { externalWalletVerificationsSelectors } from './selectors'

function* getExternalWalletVerifications(): any {
  yield put(
    externalWalletVerificationsActions.setIsLoadingExternalWalletVerifications(
      true,
    ),
  )

  try {
    const filterExternalWalletVerifications: IFilterExternalWalletVerifications =
      yield select(
        externalWalletVerificationsSelectors.filterExternalWalletVerifications,
      )

    const response: AxiosResponse = yield call(
      getexternalWalletVerificationsDataReq,
      filterExternalWalletVerifications,
    )

    console.log('shod')

    const externalWalletVerificationsList =
      (response?.data?.data
        ?.externalWalletVerifications as IExternalWalletVerifications[]) ?? []

    yield put(
      externalWalletVerificationsActions.setExternalWalletVerificationsList(
        externalWalletVerificationsList,
      ),
    )
    externalWalletVerificationsList.length === 0 ||
    externalWalletVerificationsList.length <
      filterExternalWalletVerifications.pageSize
      ? yield put(
          externalWalletVerificationsActions.setIsAllExternalWalletVerificationsFetched(
            true,
          ),
        )
      : yield put(
          externalWalletVerificationsActions.setIsAllExternalWalletVerificationsFetched(
            false,
          ),
        )
  } catch (error) {
    customToast.error(errorMessage(error))
  } finally {
    yield put(
      externalWalletVerificationsActions.setIsLoadingExternalWalletVerifications(
        false,
      ),
    )
  }
}

export function* externalWalletVerificationsSaga() {
  yield takeLatest(
    externalWalletVerificationsActions.getExternalWalletVerifications.type,
    getExternalWalletVerifications,
  )
}
